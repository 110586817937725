<template>
  <v-row align="center" justify="center">
    <v-col cols="16" sm="8" md="4">
      <validation-observer ref="obs" v-slot="{ errors, invalid, validated }">
        <v-card>
          <v-toolbar color="titleBar" dark flat dense>
            <v-toolbar-title>
              {{ tokenNeeded ? $t("tokenTitle") : $t("title") }}
            </v-toolbar-title>
            <v-spacer />
            <v-toolbar-items v-if="$config.logo != null">
              <v-img
                :src="$config.logo"
                contain
                :aspect-ratio="16 / 9"
                :width="$config.mandant == 'rzo' ? 150 : 105"
              />
            </v-toolbar-items>
            <wiki slug="login" />
          </v-toolbar>
          <v-progress-linear :active="loading" indeterminate />
          <v-stepper v-model="stepper" class="elevation-0">
            <v-stepper-items>
              <v-stepper-content step="1">
                <v-card-text class="px-8">
                  {{ $t("loginText") }}
                </v-card-text>
                <v-form @submit="login" onSubmit="return false;">
                  <validation-provider
                    vid="username"
                    rules="required"
                    :name="$t('username')"
                    v-slot="{ errors, valid }"
                  >
                    <v-text-field
                      :label="$t('username')"
                      name="username"
                      prepend-icon="mdi-account"
                      type="text"
                      v-model="username"
                      :error-messages="errors"
                      :success="valid"
                      color="primary"
                      :hint="$t('usernameHint')"
                    />
                  </validation-provider>
                  <validation-provider
                    vid="password"
                    :name="$t('password')"
                    rules="required|max:50"
                    v-slot="{ errors, valid }"
                  >
                    <v-text-field
                      :label="$t('password')"
                      name="password"
                      prepend-icon="mdi-lock"
                      :type="showCurrentPassword ? 'text' : 'password'"
                      @click:append="showCurrentPassword = !showCurrentPassword"
                      :append-icon="
                        showCurrentPassword ? 'mdi-eye-off' : 'mdi-eye'
                      "
                      v-model="password"
                      :error-messages="errors"
                      :success="valid"
                      autocomplete="off"
                    />
                  </validation-provider>
                  <v-card-actions class="flex-wrap justify-space-between">
                    <v-btn
                      name="forgotPassword"
                      :disabled="loading"
                      @click="$router.push({ name: 'password-reset' })"
                      text
                      small
                    >
                      <span
                        class="d-inline-block text-truncate"
                        :style="isMobile ? 'max-width: 290px' : ''"
                      >
                        {{ $t("first registration or forgot password") }}</span
                      >
                    </v-btn>
                    <v-btn
                      type="submit"
                      name="loggedIn"
                      color="primary"
                      :disabled="loading || invalid || !validated"
                      text
                    >
                      {{ $t("login") }}
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-stepper-content>
              <v-stepper-content step="2">
                <v-form
                  @submit="submitToken"
                  onSubmit="return false; "
                  v-if="tokenNeeded"
                >
                  <validation-provider
                    vid="token"
                    :name="$t('token')"
                    rules="required|digits:6"
                    v-slot="{ errors, valid, classes }"
                    mode="passive"
                  >
                    <v-otp-input
                      length="6"
                      type="number"
                      id="token"
                      v-model="token"
                      :label="$t('token')"
                      required
                      name="token"
                      prepend-icon="mdi-two-factor-authentication"
                      :error-messages="errors"
                      :success="(submitOnValid = valid)"
                    />
                  </validation-provider>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn name="cancel" @click="cancel" text>
                      {{ $t("cancel") }}
                    </v-btn>
                    <v-btn
                      type="submit"
                      name="loggedIn"
                      color="primary"
                      :disabled="loading || invalid || !validated"
                      text
                    >
                      {{ $t("login") }}
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card>
      </validation-observer>
    </v-col>
  </v-row>
</template>

<script>
import checkPermissions from "@/router/permissions";
import showErrors from "@/utils/mixins/showErrors";
import isMobile from "@/utils/mixins/isMobile";
import Wiki from "@/components/basics/Wiki";
import { setGfoDynamicRoutes } from "@/router/gfoDynamicRoutes";

export default {
  name: "Login",
  mixins: [showErrors, isMobile],
  components: {
    Wiki,
  },
  props: {
    next: {
      type: String,
      required: false,
      default: null,
    },
    autoUsername: {
      type: [String, Number],
      required: false,
      default: null,
    },
  },
  data: () => ({
    loading: false,
    username: "",
    email: "",
    password: "",
    token: "",
    tokenNeeded: false,
    submitOnValid: false,
    showCurrentPassword: false,
  }),
  watch: {
    submitOnValid() {
      if (this.token && this.submitOnValid === true) {
        this.submitToken();
      }
    },
    token() {
      this.$refs.obs.validate();
    },
    autoUsername: {
      handler(value) {
        if (value) {
          this.username = value;
          if (this.isStaff)
            this.$router.push({
              name: "staff-account-switch",
              params: { customerNumber: value },
            });
        }
      },
      immediate: true,
    },
  },
  computed: {
    isStaff() {
      return this.$store.getters.isReady && this.$store.getters.isStaff;
    },
    stepper() {
      return this.tokenNeeded ? 2 : 1;
    },
  },
  methods: {
    cancel() {
      this.token = "";
      this.username = "";
      this.password = "";
      this.$refs.obs.reset();
      this.tokenNeeded = false;
    },
    login() {
      var that = this;
      that.loading = true;
      that.$http
        .post(
          "login",
          {
            username: this.username,
            password: this.password,
          },
          { disableDefaultErrorHandler: true }
        )
        .then(function (response) {
          that.$store.commit("login", response.data);
          if (
            that.$store.state.session.twoFactorEnabled === true &&
            that.$store.state.session.twoFactorVerified === false &&
            that.$store.state.session.twoFactorConfirmed === true &&
            that.tokenNeeded === false &&
            response.data.is_staff === false
          ) {
            console.log("login: 2FA token needed!");
            that.tokenNeeded = true;
          } else if (
            that.$store.state.session.twoFactorEnabled === true &&
            that.$store.state.session.twoFactorConfirmed === false &&
            response.data.is_staff === false
          ) {
            console.log("login: redirect to 2FA setup");
            that.$router.push({
              name: "two-factor-auth",
              params: { ignoreSessionCheck: true },
            });
          } else {
            that.loadProfile();
          }
        })
        .catch(function (error) {
          /** too many requests (throttling) */
          if (
            (error.status == 429 || error.status == 403) &&
            that.$refs.obs != null
          ) {
            that.$refs.obs.setErrors({
              password: error.message,
            });
          } else if (that.$refs.obs != null) {
            that.$refs.obs.setErrors(error.data);
            that.showErrors(that.$refs.obs.getUnresolvedErrors(error));
          } else {
            console.log(">>> error: ", error);
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    submitToken() {
      var that = this;
      that.loading = true;
      that.$http
        .put("login/verify", { token: this.token })
        .then(function (response) {
          that.loadProfile();
        })
        .catch(function (error) {
          if (error.invalid()) {
            // TODO: Warnung ein Änderung am Backend in der Validierung kann das Mapping kaputtmachen
            if (error.data.detail) {
              error.data.token = error.data.detail;
              delete error.data.detail;
            }
            if (that.$refs.obs != null) {
              that.$refs.obs.setErrors(error.data);
              that.showErrors(that.$refs.obs.getUnresolvedErrors(error));
            } else {
              console.log(">>> error: ", error);
            }
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    loadProfile() {
      var that = this;
      that.$http
        .get("profile")
        .then(async function (response) {
          that.$store.commit("setSession", response.data);
          that.tokenNeeded = false;
          that.username = "";
          that.password = "";
          that.token = "";
          that.$refs.obs.reset();

          const next = that.next ? that.$router.resolve(that.next) : null;

          if (that.$store.getters.isStaff && that.$store.getters.isFallback)
            that.$router.push({
              name: "staff-account-switch",
              params: next != null ? next.route.params : {},
              query: next != null ? next.route.query : {},
            });
          else if (that.next) {
            await setGfoDynamicRoutes(that.$config);
            if (next == null) {
              console.log(
                "login redirect to previous URL ",
                that.next,
                " will be ignored: route not found!"
              );
              that.$router.push({ name: "home" });
            } else if (checkPermissions(next.route)) {
              console.log(
                "login redirect to previous URL ",
                next.route.fullPath,
                " ..."
              );
              that.$router.push({
                name: next.route.name,
                params: next.route.params,
              });
            } else {
              console.log(
                "login redirect to previous URL ",
                next.route.fullPath,
                " will be ignored due to missing permissions!"
              );
              that.$router.push({ name: "home" });
            }
          } else {
            that.$snotify.success(
              that.$i18n.t("Hello {username}, welcome to {app}!", {
                username: that.$store.getters.username,
                app: that.$config.title,
              })
            );
            that.$router.push({ name: "home" });
          }
        })
        .catch(function (error) {});
    },
  },
};
</script>

<i18n>
{
  "en": {
    "title": "Login",
    "tokenTitle" : "Two factor token",
    "username": "Username",
    "password": "Password",
    "login": "Login",
    "forgot password": "Forgot password",
    "first registration or forgot password": "first registration or forgot password",
    "Hello {username}, welcome to {app}!": "Hello {username}, welcome to {app}!",
    "token": "Token",
    "cancel": "Cancel",
    "usernameHint": "Use your customer number or email to login.",
    "loginText": "Please login with your customer number or your email address."
  },
  "de": {
    "title": "Anmeldung",
    "tokenTitle" : "Authentifizierungs Token",
    "username": "Benutzername",
    "password": "Passwort",
    "login": "anmelden",
    "forgot password": "Passwort vergessen",
    "first registration or forgot password": "Erstanmeldung oder Passwort vergessen",
    "Hello {username}, welcome to {app}!": "Hallo {username}, willkommen im {app}!",
    "token": "Token",
    "cancel": "Abbrechen",
    "usernameHint": "Verwenden Sie Ihre Kundennummer oder Email zum anmelden.",
    "loginText": "Melden Sie sich bitte mit Ihrer Kundennummer oder Ihrer Email Adresse an."
  }
}
</i18n>

<style scoped></style>
